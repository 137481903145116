import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'

const WhatWeDo = loadable(() => import('../components/WhatWeDo'))
const CaseStudy = loadable(() => import('../components/CaseStudy'))
const Press = loadable(() => import('../components/Press'))
const OurProcess = loadable(() => import('../components/OurProcess'))
const LoadScreen = loadable(() => import('../components/LoadScreen'))

import { Layout, SEO, Hero } from '../components'

import intrmodlFallbackPreview from '../images/intrmodl_preview.png'
import dashboard from '../images/dashboard.png'
import slateplanPreview from '../images/slateplan_preview.png'
import socialCard from '../images/social_card_home.png'

const Landing = ({ location }) => {
  const [withLoadingScreen, setWithLoadingScreen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('cacheReady')) {
      setWithLoadingScreen(true)
      localStorage.setItem('cacheReady', true)
    }
  }, [])

  return (
    <Layout location={location}>
      {withLoadingScreen && <LoadScreen />}
      <SEO
        title="Home"
        description="GNAR is a technology and consulting company that transforms information into actionable insight. We strategize with businesses, and develop valuable tech."
        socialTitle="GNAR LLC"
        image={socialCard}
      />
      <Hero hasLoadingScreenActive={withLoadingScreen} />
      <WhatWeDo />
      <CaseStudy
        projectName="slingshot"
        backgroundColor="slateplan"
        descriptionTitle="A Shared Task List"
        description="Effortless collaboration with clients & vendors, minus the sign-up fuss! Our app makes task management a breeze. Say goodbye to email overload! 😎"
        href="slingshot"
        previewImage={dashboard}
      />
      <CaseStudy
        projectName="Intrmodl"
        backgroundColor="intrmodl"
        descriptionTitle="Productivity"
        description="An operation management tool that uses IoT and digital reporting to generate a data-driven view of intermodal operations. 🤓"
        href="intrmodl"
        previewImage={intrmodlFallbackPreview}
      />
      <Press />
      <CaseStudy
        projectName="Slateplan"
        backgroundColor="slateplan"
        descriptionTitle="Elegant Sales"
        description="Making budgeting and bidding on smart home designs easier. Get paid faster. 💸"
        href="slateplan"
        previewImage={slateplanPreview}
      />
      <OurProcess />
    </Layout>
  )
}

export default Landing
